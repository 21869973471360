<template>
<div>
  <b-card class="card-custom gutter-b">
    <template #header>
      <b-row class="w-100 d-flex align-items-center" no-gutters>
        <b-col xs=6>
          <h5 class="m-0">Meeting Room</h5>
        </b-col>
        <b-col xs=6 class="text-right">

        </b-col>
      </b-row>
    </template>
    <form @submit.prevent="doSave()">
      <b-row>
        <b-col sm=4>
          <b-form-group label="Main Image" description="Click to change/ add Image" class="project-img">
            <label for="cm_form_img" class="w-100" ref="previewImage">
              <b-img-lazy :src="(form.data.image) ? form.data.image : require('@/assets/images/default.png')" fluid class="w-100" />
              <input type="file" ref="cm_form_img" hidden id="cm_form_img" @change="previewImage($event,'MAIN')" accept="image/*">
            </label>
          </b-form-group>
          <b-form-group label="Other Image">
            <input type="file" ref="cm_form_img_other" multiple hidden id="cm_form_img_other" @change="previewImage($event,'OTHER')" accept="image/*">
            <b-row>
              <b-col sm=6 md=3 v-for="(tImg,tImgIndex) in form.data.child.image" :key="`tImg-${tImgIndex}`" v-show="!tImg.deleted">
                <span class="cm-delete-img" @click="deleteImage(tImgIndex)">+</span>
                <b-img :src="tImg.image" style="height:120px;object-fit:contain" fluid class="w-100 mb-3" thumbnail />
              </b-col>
              <b-col sm=6 md=3>
                <label for="cm_form_img_other" class="btn btn-success btn-block m-0 d-flex justify-content-center align-items-center " style="height:120px;font-size:1000%">+</label>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
        <b-col sm=8>
          <b-form-group label="Headline">
            <b-input v-model="form.data.title" />
          </b-form-group>
          <b-form-group label="Intro">
            <wysiwyg v-model="form.data.content" />
          </b-form-group>
        </b-col>
        <b-col sm=12>
          <h4 class="py-3 mb-5 border-bottom">Facility</h4>
          <b-row>
            <b-col sm=6 v-for="(fac,facIndex) in form.data.child.facility" :key="`fac-${facIndex}`" class="mb-5 pb-5 border-bottom" v-show="!fac.deleted">
              <b-form-group label="Title">
                <b-input v-model="fac.title" />
              </b-form-group>
              <b-form-group label="Content">
                <wysiwyg v-model="fac.content" />
              </b-form-group>
              <b-button variant="danger" block @click="deleteFacility(facIndex)">Remove</b-button>
            </b-col>
            <b-col sm=12 class="py-4">
              <b-button variant="success" block @click="addFacility()">+ Add More</b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm=12 class="pt-5 mt-5 border-top d-flex justify-content-end">
          <b-button type="submit" :disabled="form.loading" variant="primary" class="ml-3">
            <b-spinner small v-show="form.loading" /> Save
          </b-button>
        </b-col>
      </b-row>
    </form>
  </b-card>
</div>
</template>

<script>
import {
  SET_BREADCRUMB
} from "@/core/services/store/breadcrumbs.module";
import {
  validationMixin
} from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'
import {
  baseUrl
} from '@/core/config/baseUrl'
export default {
  data() {
    return {
      form: {
        loading: false,
        data: {
          id: null,
          image: null,
          title: null,
          content: null,
          id_parent: null,
          type: 'MEETING ITEM',
          child: {
            image: [],
            facility: []
          }
        },
        deleteId: null
      }
    }
  },
  mixins: [validationMixin],
  validations: {
    form: {
      data: {
        title: {
          required
        },
        type: {
          required
        }
      }
    }
  },
  methods: {
    doLoad() {
      let url = baseUrl + `/api/post`
      let payload = {
        perPage: '~',
        type: 'MEETING ITEM'
      }
      this.fetchData(url, 'POST', payload)
        .then(res => {
          if (res.data.length > 0) {
            let resData = res.data[0]
            this.form.data.id = resData.id
            this.form.data.image = resData.image
            this.form.data.title = resData.title
            this.form.data.content = resData.content
            this.form.data.id_parent = resData.id_parent
            this.form.data.type = 'MEETING ITEM'
            this.form.data.child.facility = []
            this.form.data.child.image = []
            resData.child.map((x) => {
              switch (x.type) {
                case 'MEETING ITEM FACILITY':
                  x.deleted = false
                  this.form.data.child.facility.push(x)
                  break;
                case 'MEETING ITEM IMAGES':
                  x.deleted = false
                  this.form.data.child.image.push(x)
                  break;
              }
            })
          }
        })
        .catch(e => {
          console.log(e)
          this.notif('Failed', 'Get:Internal server error', 'danger')
        });
    },
    doSave() {
      this.form.loading = true
      this.$v.form.data.$touch()
      if (this.$v.form.data.$invalid) {
        this.form.loading = false
        this.notif('Warning', 'Please complete required field', 'warning')
      } else {
        let url = baseUrl + '/api/post/create'
        if (this.form.data.id)
          url = baseUrl + '/api/post/update'
        this.fetchData(url, 'POST', this.form.data)
          .then(res => {
            if (res.status) {
              this.notif('Success', res.msg, 'success')
              this.doLoad()
            } else {
              this.notif('Failed', res.msg, 'danger')
            }
            this.form.loading = false
          })
          .catch(e => {
            this.form.loading = false
            this.notif('Failed', 'Save:Internal server error', 'danger')
          });
      }
    },
    fetchData(url, type, data) {
      let params = {
        method: type,
        headers: {
          'Content-Type': 'application/json',
        }
      }
      if (data)
        params.body = JSON.stringify(data)
      return fetch(
          url, params
        )
        .then(response => response.json())
    },
    addFacility() {
      let newItem = {
        id: null,
        image: null,
        title: null,
        content: null,
        id_parent: null,
        type: 'MEETING ITEM FACILITY',
        deleted: false,
      }
      this.form.data.child.facility.push(newItem)
    },
    deleteFacility(idx) {
      let id = this.form.data.child.facility[idx].id
      if (id > 0) {
        this.form.data.child.facility[idx].deleted = true
      } else {
        this.form.data.child.facility.splice(idx, 1);
      }
    },
    previewImage(event, target) {
      let theImg = null
      let vm = this
      const inp = this.$refs.cm_form_img
      const inpOther = this.$refs.cm_form_img_other
      if (target == 'MAIN') {
        theImg = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(theImg);
        reader.onload = function () {
          vm.form.data.image = reader.result
          inp.type = 'text';
          inp.type = 'file';
        };
        reader.onerror = function () {
          vm.form.data.image = null
          inp.type = 'text';
          inp.type = 'file';
        };
      } else {
        theImg = event.target.files
        for (let x = 0; x < theImg.length; x++) {
          let reader = new FileReader();
          reader.readAsDataURL(theImg[x]);
          reader.onload = function () {
            let pImg = {
              id: null,
              image: reader.result,
              title: 'MEETING ITEM IMAGES',
              content: null,
              id_parent: null,
              type: 'MEETING ITEM IMAGES',
              deleted: false,
            }
            vm.form.data.child.image.push(pImg)
            inpOther.type = 'text';
            inpOther.type = 'file';
          };
          reader.onerror = function (e) {
            console.log(e)
            inpOther.type = 'text';
            inpOther.type = 'file';
          };
        }
      }
    },
    deleteImage(idx) {
      let id = this.form.data.child.image[idx].id
      if (id > 0) {
        this.form.data.child.image[idx].deleted = true
      } else {
        this.form.data.child.image.splice(idx, 1);
      }
    },
    clearForm() {
      this.$v.form.data.$reset()
      this.form.data = {
        id: null,
        image: null,
        title: null,
        content: null,
        id_parent: null,
        type: 'MEETING ITEM',
        child: {
          image: [],
          facility: []
        }
      }
      this.form.deleteId = null
    },
    notif(title, msg, type) {
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 5000,
        variant: type,
        solid: true,
        toaster: 'b-toaster-bottom-left'
      })
    },
    thousand(num) {
      if (num) {
        let num_parts = num.toString().split(",");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return num_parts.join(",");
      } else {
        return 0
      }
    },
  },
  watch: {
    paging: {
      handler() {
        this.doLoad()
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Meeting Room"
    }])
    this.doLoad()
    this.$root.$on('bv::modal::hide', () => {
      this.clearForm()
    })
  },
}
</script>

<style lang="scss">
.cm-delete-img {
  position: absolute;
  top: -10px;
  right: 0px;
  background: red;
  color: white;
  font-size: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  transform: rotate(45deg);
  cursor: pointer;
}

.project-caption {
  padding: 10px;
  border: 1px dashed grey;

  h6,
  p {
    color: white;
    text-shadow: 1px 1px grey;
  }
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.modal-dialog.modal-xxl {
  max-width: 90%;
}

.project-img {
  div[role="group"] {
    position: relative;
  }
}
</style>
